import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment/locale/ru';
import MainPage from './page/main';
import LogPage from './page/log';
import UsersPage from './page/users';
import UserPage from './page/user';
import SettingsPage from './page/settings';
import DocumentPage from './page/document';
import UpdatePage from './page/update';
import { AppContext } from './context/app.context';
import { DataStorage } from './enum/dataStorage';
import Loader from './component/loader';
import { Rest } from './rest';
import Utils from './utils';
import i18n from './i18n';
import NotFoundRegistrationForm from './component/notFoundRegistrationForm';
import { AccessKey } from './enum/accessKey';
import { DocumentStatus } from './enum/documentStatus';
import Header from './component/header';
import { SnackbarProvider } from 'notistack'

export default class App extends React.Component {

  constructor() {
    super();
    this.state = {
      appVersion: 20,
      settingsCache: {},
      settings: {},
      loading: true,
      needUpdate: false,
      openModalNotFoundRegisteredUsers: false,
      headerVisibility: true
    };

    this.DefaultSettings = [
      new SettingsItem('AdminList', '', function () { return []; }),
      new SettingsItem('ExternalSystemClientId', '', function () { return ''; }),
      new SettingsItem('NotifyResponsible', '', function () { return 'Y'; }),
      new SettingsItem('NotifySigner', '', function () { return 'N'; }),
      new SettingsItem('NotificationSubscriptions', '', function () { return [DocumentStatus.denied, DocumentStatus.cancelled, DocumentStatus.signed]; }),
      new SettingsItem('Version', '', function () { return 0; }, false)
    ];

    this.onlineConsult = `(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b13771686/crm/site_button/loader_1_p5lgb9.js');`;

    Modal.setAppElement('#root');
  }

  componentDidMount() {
    this.init();

    const script = document.createElement("script");
    script.text = this.onlineConsult;
    document.body.appendChild(script);
  }

  async init() {
    const _ = this;
    try {
      const currentUser = await Rest.init();
      moment.locale(Rest.getLang());
      i18n.changeLanguage(Rest.getLang());
      _.setState({
        currentUser: currentUser
      }, async () => {
        await _.initAppSettings();
        _.setState({
          loading: false
        });

        if (parseInt(_.state.settings.Version.Value) < _.state.appVersion) {
          _.setNeedUpdate();
        }
        else {
          if (await _.tryCheckInstall()) {
            _.tryFindRegisteredUsers();
          }
        }
      });
    }
    catch (err) {
      _.setState({
        loading: false
      });
    }
  }

  setAppSettings = async (key, data) => {
    var rd = {
      ENTITY: DataStorage.settings,
      NAME: key,
      ID: '',
      PROPERTY_VALUES: {
        VALUE: JSON.stringify(data)
      }
    };

    if (this.state.settingsCache[key]) {
      rd.ID = this.state.settingsCache[key].ID;
      const result = await Rest.callMethod('entity.item.update', rd);
      return true;
    }
    else {
      const result = await Rest.callMethod('entity.item.add', rd);
      return true;
    }
  }

  initAppSettings = () => {
    const _ = this;
    return new Promise((resolve, reject) => {
      _.setState({
        settingsCache: {}
      }, async () => {
        await _.getAppSettingsCache();
        var appSettings = {};
        _.DefaultSettings.forEach(function (item) {
          const appSettingsValue = _.getAppSettings(item.Key);
          appSettings[item.Key] = new SettingsItem(item.Key, appSettingsValue ? appSettingsValue : item.Default(), item.Default, item.SaveInSettings);
        });
        _.setState({
          settings: appSettings
        }, () => {
          resolve(true);
        });
      });
    });
  }

  getAppSettingsCache = async () => {
    const settingsCache = {};
    try {
      const result = await Rest.callMethod('entity.item.get', { ENTITY: DataStorage.settings }, true);
      result.items.forEach(function (item) {
        settingsCache[item.NAME] = item;
      });
      this.setState({
        settingsCache: settingsCache
      });
    }
    catch (err) {
      console.error('getAppSettingsCache', err);
      this.setNeedUpdate();
    }
  }

  getAppSettings = (key) => {
    return this.state.settingsCache[key] ? Utils.tryParseJson(this.state.settingsCache[key].PROPERTY_VALUES.VALUE) : false;
  }

  updateComplete = () => {
    this.setState({
      needUpdate: false
    });
  }

  access = (key) => {
    const user = this.state.currentUser || null;
    if (!user)
      return false;

    switch (key) {
      case AccessKey.settings:
        if (this.state.settings && this.state.settings['AdminList'].Value.includes(user.Id)) {
          return true;
        }
        return user.isAdmin;
    }
    return user.isAdmin;
  }

  getCurrentUser = () => {
    return this.state.currentUser;
  }

  setNeedUpdate() {
    this.setState({
      needUpdate: true
    });
  }

  async tryCheckInstall() {
    //для ситуации когда переустановили с сохранением параметров
    try {
      const result = await Rest.callMethod('app.info');
      if (result.items && result.items.length > 0) {
        if (!result.items[0].INSTALLED) {
          this.setNeedUpdate();
          return false;
        }
        return true;
      }
    }
    catch (err) {
      console.error('tryCheckInstall', err);
      return false;
    }
  }

  async tryFindRegisteredUsers() {
    try {
      const result = await Rest.callMethod('entity.item.get', { ENTITY: DataStorage.user });
      if (result.items.length < 1) {
        this.setState({
          openModalNotFoundRegisteredUsers: true
        });
      }
    }
    catch (err) {
      console.error('registered users', err);
    }
  }

  onHideModal = () => {
    this.setState({
      openModalNotFoundRegisteredUsers: false
    });
  }

  setHeaderVisibility = (state) => {
    console.log('setHeaderVisibility', state);
    this.setState({
      headerVisibility: state
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="app container-fluid my-2">
          <div className="text-center">
            <Loader />
          </div>
        </div>
      );
    }

    /*
    return (
      <div className="app container-fluid my-2">
        <TestPage />
      </div>
    );*/

    if (!Rest.isInitComplete()) {
      return (
        <div className="app container-fluid my-2">This is Sparta! (not bitrix)</div>
      );
    }

    return (
      <AppContext.Provider value={{
        appVersion: this.state.appVersion,
        getCurrentUser: this.getCurrentUser,
        settings: this.state.settings, initAppSettings: this.initAppSettings, setAppSettings: this.setAppSettings, updateComplete: this.updateComplete,
        access: this.access, setHeaderVisibility: this.setHeaderVisibility
      }}>
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={2000} >
        <Router>
          <div className="app container-fluid my-2">
            {!this.state.loading && !this.state.needUpdate &&
              <>
                {this.state.headerVisibility &&
                  <Header />
                }
                <Switch>
                  <Route path="/users" component={UsersPage} />
                  <Route path="/user/:id" component={UserPage} />
                  <Route path="/log" component={LogPage} />
                  <Route path="/settings" component={SettingsPage} />
                  <Route path="/document/:id" component={DocumentPage} />
                  <Route path="/" component={MainPage} />
                </Switch>
              </>
            }
            {this.state.needUpdate &&
              <UpdatePage currentVersion={this.state.settings.Version.Value} currentUser={this.state.currentUser} />
            }
            <NotFoundRegistrationForm isOpen={this.state.openModalNotFoundRegisteredUsers} onHide={this.onHideModal} />
          </div>
        </Router>
        </SnackbarProvider>
      </AppContext.Provider>
    );
  }
}

class SettingsItem {
  constructor(key, value, funcDefault, saveInSettings = true) {
    this.Key = key;
    this.Value = value;
    this.Default = funcDefault;
    this.SaveStatus = true;
    this.SaveInSettings = saveInSettings;
  }
}