import Utils from "../utils";

class UserSignData {
    ID: any;
    PROPERTY_VALUES: any;
    constructor(fields: any) {
        Object.assign(this, fields);
    }

    get Id(): number {
        return parseInt(this.ID) || 0;
    }

    get Email(): string {
        return this.PROPERTY_VALUES?.EMAIL;
    }

    get UserId(): number {
        return parseInt(this.PROPERTY_VALUES.USER) || 0;
    }

    get ResponsibleId(): number[] | null {
        const result = Utils.tryParseJson(this.PROPERTY_VALUES.RESPONSIBLE);
        if (result) {
            return result as number[];
        }
        return null;
    }

    get AllResponsibleId(): number[] {
        const responsible = this.ResponsibleId ?? [];
        if (!responsible.includes(this.UserId)) {
            responsible.push(this.UserId);
        }
        return responsible;
    }
}

export default UserSignData;