import axios from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from '../context/app.context';
import { Rest } from "../rest";

const SignCountView: FC = () => {
    const { t } = useTranslation();
    const [signCount, setSignCount] = useState<number>();
    const [smsCount, setSmsCount] = useState<number>();
    const [licenseDeadline, setLicenseDeadline] = useState<Date>();
    const context = useContext(AppContext);

    useEffect(() => {
        loadLicenseInfo();
    }, [context.settings.ExternalSystemClientId]);

    const loadLicenseInfo = async () => {
        const currentUserAuthData = Rest.getAuth();
        if (!currentUserAuthData)
            return;
        try {
            const result = (await axios.post('/getLicenseInfo', currentUserAuthData)).data;
            console.log(result)
            if (result.signCount !== undefined || result.smsCount !== undefined) {
                setSignCount(result.signCount ?? 0);
                setSmsCount(result.smsCount ?? 0);
            }
            if (result.licenseDeadline) {
                setLicenseDeadline(new Date(result.licenseDeadline));
            }

            for (const key in result.errors ?? {}) {
                console.error(key, result.errors[key]);
            }
        }
        catch (err) {
            console.error('license info', err);
            setSignCount(undefined);
            setLicenseDeadline(undefined);
        }
    }

    return (<>
        {!isNaN(Number(licenseDeadline?.getTime())) && <a href="https://signx.cloud/training-and-setup/tariffs" className="me-2" target="_blank">{t('license-deadline')}{licenseDeadline?.toLocaleDateString()}</a>}
        {signCount !== undefined && <a href="https://signx.cloud/training-and-setup/tariffs" className="me-2" target="_blank">SMS: {smsCount} | {t('sign-count')}{signCount}</a>}
    </>);
    // <a className="btn btn-success btn-md btn-floating me-1" role="button">
    //     <i className="fas fa-shopping-cart"></i>
    // </a>
}

export default SignCountView;
