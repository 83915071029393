import React from "react";
import { Rest } from "../rest";
import DefaultAvatar from '../images/user-default-avatar.svg';
import ValidationError from "../service/fieldValidator/validationError";
import UserSignData from "./userSignData";

class User {
    id: number;
    NAME: string = '';
    LAST_NAME: string = '';
    SECOND_NAME: string = '';
    EMAIL: string = '';
    PERSONAL_PHOTO: string = '';
    WORK_POSITION: string = '';
    PERSONAL_MOBILE: string = '';
    admin: boolean = false;
    constructor(fields: any, admin: boolean = false) {
        Object.assign(this, fields);
        this.id = parseInt(fields?.ID ?? fields?.id) || 0;
        this.admin = admin;
    }

    get Id(): number {
        return this.id;
    }

    get Link(): string {
        return `https://${Rest.getDomain()}/company/personal/user/${this.Id}/`;
    }

    get LinkView() {
        const user = this;
        return (
            <a href={user.Link} target="_blank" title={user.FullName}>
                {user.Photo
                    ? <span className="tasks-grid-avatar" style={{ backgroundImage: `url(${user.Photo})` }}></span>
                    : <span className="tasks-grid-avatar bg-dark" style={{ backgroundImage: `url(${DefaultAvatar})` }}></span>
                }
                {user.FullName}
            </a>
        );
    }

    get LinkIconView() {
        const user = this;
        return (
            <a href={user.Link} target="_blank" title={user.FullName}>
                {user.Photo
                    ? <span className="tasks-grid-avatar" style={{ backgroundImage: `url(${user.Photo})` }}></span>
                    : <span className="tasks-grid-avatar bg-dark" style={{ backgroundImage: `url(${DefaultAvatar})` }}></span>
                }
            </a>
        );
    }

    get Photo() {
        return this.PERSONAL_PHOTO && this.PERSONAL_PHOTO.length > 0 ? this.PERSONAL_PHOTO : false;
    }

    get Position() {
        return this.WORK_POSITION;
    }

    get FullName() {
        if (this.NAME && this.NAME.length > 0) {
            if (this.SECOND_NAME && this.SECOND_NAME.length > 0) {
                if (this.LAST_NAME && this.LAST_NAME.length > 0) {
                    return this.NAME + ' ' + this.SECOND_NAME + ' ' + this.LAST_NAME;
                }
                return this.NAME + ' ' + this.SECOND_NAME;
            }
            if (this.LAST_NAME && this.LAST_NAME.length > 0) {
                return this.NAME + ' ' + this.LAST_NAME;
            }
            return this.NAME;
        }

        if (this.EMAIL && this.EMAIL.length > 0)
            return this.EMAIL;

        return 'ID: ' + this.Id;
    }

    get isAdmin(): boolean {
        return this.admin;
    }

    validateAsSigner(userSignData: UserSignData) {
        const _ = this;
        const errors = [];

        if (!_.EMAIL || _.EMAIL.length <= 0) {
            errors.push(new ValidationError('field-email', { field: 'Y' }));
        }

        if (!_.LAST_NAME || _.LAST_NAME.length <= 0) {
            errors.push(new ValidationError('field-last-name', { field: 'Y' }));
        }

        if (!_.NAME || _.NAME.length <= 0) {
            errors.push(new ValidationError('field-first-name', { field: 'Y' }));
        }

        if (!_.SECOND_NAME || _.SECOND_NAME.length <= 0) {
            errors.push(new ValidationError('field-second-name', { field: 'Y', warning: 'Y' }));
        }

        if (!_.PERSONAL_MOBILE || _.PERSONAL_MOBILE.length <= 0) {
            errors.push(new ValidationError('field-mobile-phone', { field: 'Y' }));
        }

        if (!userSignData || !userSignData.Email || userSignData.Email.length < 1) {
            errors.push(new ValidationError('field-sign-email', { field: 'Y' }));
        }

        return errors;
    }
}

export default User;