interface IProps {
    className?: string,
    inModal?: boolean,
    rowClassName?: string
}

const InputContainer: React.FC<IProps> = props => {
    return (
        <div className={`${props.inModal ? 'col-12' : 'col-12 col-lg-8 col-xl-7'} ${props.className ? props.className : ''}`}>
            {props.children}
        </div>
    );
}

export default InputContainer;