export const HomeRoutesPath = {
    /**
     * same as Config.ServerUrl
     */
    ServerUrl: `https://${window.location.hostname}`,
    // ServerUrl: "https://signx-dev1.app.apik-it.ru",
    /**
     * end with "/", same as Config.HomeRoutePath
     */
    Prefix: "/"
};