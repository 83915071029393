import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import UserTable from '../component/table/userTable';
import Filter from '../component/filter/filter';
import FilterItem from '../component/filter/filterItem';
import FilterType from '../component/filter/filterType';

export default class UsersPage extends React.Component {
    constructor() {
        super();
        this.filterItems = [
            new FilterItem(FilterType.Hidden, 'property_email', 'field-email', { Typing: 'Y', PREFIX: 'Y' }),
            new FilterItem(FilterType.UserSelect, 'property_user', 'field-user', { Default: 'Y' })
        ];
        this.table = React.createRef();
        this.filter = React.createRef();
    }

    refreshTable = () => {
        if (this.table && this.table.current) {
            this.table.current.tryLoadData();
        }
    }

    render() {
        return (
            <div>
                <div className="d-xl-flex mb-3 mt-3">
                    <div className="app-filter-actions mb-2 mb-xl-0">
                        <Link className="btn btn-success px-3 m-0 me-2 text-nowrap" to="/user/0"><i class="fas fa-plus me-2"></i><Trans>user-add</Trans></Link>
                    </div>
                    <Filter ref={this.filter} id="filter-users" items={this.filterItems} refreshTable={this.refreshTable} />
                </div>
                <UserTable ref={this.table} filter={this.filter}/>
            </div>
        );
    }
}