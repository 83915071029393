import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { MDBSwitch } from "mdb-react-ui-kit";
import { ErrorHandler } from '../service/errorHandler';
import { Rest } from "../rest";
import InputContainer from "./layout/inputContainer";
import { HomeRoutesPath } from "../page/homeRoutes";
import i18n from "../i18n";

interface IProps {
}

interface IState {
    items: string[],
    error: string
}

export default class RobotAdding extends React.Component<IProps, IState> {
    readonly RobotAddingHandler = `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}robot`;

    constructor(props: IProps) {
        super(props);
        this.state = {
            items: [],
            error: ''
        };
    }

    componentDidMount() {
        this.checkRobotList();
    }

    addNewDocToSign = async () => {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'nds_robot',
                HANDLER: this.RobotAddingHandler + '/addnewdocsignAddingHandler',
                AUTH_USER_ID: 1,
                NAME: 'Отправка документа на подпись',
                USE_SUBSCRIPTION: 'Y',
                'PROPERTIES': {
                    'document_id': {
                        'Name': 'Документ',
                        'Type': 'string',
                    },
                    'deal_id': {
                        'Name': 'Сделка',
                        'Type': 'string'
                    },
                    'user': {
                        'Name': 'Ответственный сотрудник',
                        'Type': 'string'
                    },
                    'signatures_json': {
                        'Name': 'Подписи (json)',
                        'Type': 'text'
                    },
                    'agreement_number': {
                        'Name': '№ дополнительного соглашения',
                        'Type': 'string'
                    },
                    'agreement_date': {
                        'Name': 'Дата дополнительного соглашения',
                        'Type': 'string'
                    },
                    'auto_complete': {
                        'Name': 'Акт, отправка на подписание с отслеживанием срока ожидания подписания',
                        'Type': 'bool',
                    },
                    'observers': {
                        Name: 'Наблюдатели',
                        Type: 'user',
                        Multiple: 'Y',
                    }
                },
                RETURN_PROPERTIES: {
                    'document_id': {
                        'Name': 'ID документа',
                        'Type': 'string'
                    },
                    'document_text': {
                        'Name': 'Текст статуса документа',
                        'Type': 'string'
                    },
                    'document_status': {
                        'Name': 'Код статус документа',
                        'Type': 'string'
                    },
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                    'error': {
                        'Name': 'Отчет об ошибке',
                        'Type': 'string'
                    }
                }
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`addNewDocToSign`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    checkDocStatus = async () => {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'cgs_robot',
                HANDLER: this.RobotAddingHandler + '/checkdocstatusAddingHandler',
                AUTH_USER_ID: 1,
                NAME: 'Проверка статуса документа',
                USE_SUBSCRIPTION: 'Y',
                'PROPERTIES': {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    wait_for: {
                        Name: 'Ожидать статус',
                        Type: "select",
                        Options: {
                            EmailComplitedDoc: i18n.t("document-status-EmailComplitedDoc"),
                            Denied: i18n.t("document-status-Denied"),
                            Cancelled: i18n.t("document-status-Cancelled"),
                            Signed: i18n.t("document-status-Signed"),
                            AwaitingSignatures: i18n.t("document-status-AwaitingSignatures"),
                            Created: i18n.t("document-status-Created"),
                            Opened: i18n.t("document-status-Opened"),
                            Completed: i18n.t("document-status-Completed"),
                            EmailToActorSended: i18n.t("document-status-EmailToActorSended"),
                            EmailToAuthorSended: i18n.t("document-status-EmailToAuthorSended"),
                            RegisteredInAvisHash: i18n.t("document-status-RegisteredInAvisHash"),
                            AwaitingPayment: i18n.t("document-status-AwaitingPayment"),
                            AwaitingFinalization: i18n.t("document-status-AwaitingFinalization"),
                        },
                    }
                },
                RETURN_PROPERTIES: {
                    'document_id': {
                        'Name': 'ID документа',
                        'Type': 'string'
                    },
                    'agreement_id': {
                        'Name': 'ID документа доп. соглашения',
                        'Type': 'string'
                    },
                    'report_id': {
                        'Name': 'ID документа отчета о подписании',
                        'Type': 'string'
                    },
                    'document_text': {
                        'Name': 'Текст статуса документа',
                        'Type': 'string'
                    },
                    'document_status': {
                        'Name': 'Код статус документа',
                        'Type': 'string'
                    },
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    }
                }
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`checkDocStatus`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    getFileLink = async () => {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'gfl_robot',
                HANDLER: this.RobotAddingHandler + '/downloadFileHandler',
                AUTH_USER_ID: 1,
                NAME: 'Получение файла документа',
                USE_SUBSCRIPTION: 'Y',
                'PROPERTIES': {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    file_type: {
                        Name: 'Тип файла',
                        Type: "select",
                        Options: {
                            SIGNED_FILE: "Подписанный файл",
                            REPORT_FILE: "Отчет о подписании",
                            AGREEMENT_FILE: "Доп. соглашение",
                        },
                    }
                },
                RETURN_PROPERTIES: {
                    'link': {
                        'Name': 'Ссылка на файл документа',
                        'Type': 'string'
                    },
                    app_text: {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    app_status: {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                }
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`checkDocStatus`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    createLink = async () => {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'link_robot',
                HANDLER: `${this.RobotAddingHandler}/create-sign-link-handler`,
                NAME: 'Создать ссылку на документ',
                USE_SUBSCRIPTION: 'Y',
                PROPERTIES: {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    'email': {
                        'Name': 'Контакт подписанта',
                        'Type': 'string'
                    },
                },
                RETURN_PROPERTIES: {
                    'link': {
                        'Name': 'Ссылка',
                        'Type': 'string'
                    },
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                    'error': {
                        'Name': 'Отчет об ошибке',
                        'Type': 'string'
                    }
                }
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`createLink`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    resendEmails = async () => {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'resend_robot',
                HANDLER: `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}robot/resend-emails-handler`,
                NAME: 'Повторная отправка уведомления',
                USE_SUBSCRIPTION: 'Y',
                PROPERTIES: {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                },
                RETURN_PROPERTIES: {
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                }
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`resend_robot`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    checkRobotList = async () => {
        try {
            const result = await Rest.callMethod('bizproc.robot.list');
            this.setState({
                items: result.items
            })
        }
        catch (err) {
            const message = ErrorHandler.handle(`checkRobotList`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    deleteRobotAddNewDocToSign = async () => {
        try {
            await Rest.callMethod('bizproc.robot.delete', {
                CODE: 'nds_robot'
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`deleteRobotAddNewDocToSign`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    deleteRobotCheckDocStatus = async () => {
        try {
            await Rest.callMethod('bizproc.robot.delete', {
                CODE: 'cgs_robot'
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`deleteRobotCheckDocStatus`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    deleteGetFileLink = async () => {
        try {
            await Rest.callMethod('bizproc.robot.delete', {
                CODE: 'gfl_robot'
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`deleteGetFileLink`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    deleteCreateLink = async () => {
        try {
            await Rest.callMethod('bizproc.robot.delete', {
                CODE: 'link_robot'
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`deleteCreateLink`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    deleteResendEmails = async () => {
        try {
            await Rest.callMethod('bizproc.robot.delete', {
                CODE: 'resend_robot'
            });
            this.reloadRobots();
        }
        catch (err) {
            const message = ErrorHandler.handle(`deleteResendEmails`, err);
            if (message && message.length > 0) {
                this.setState({
                    error: message
                });
            }
        }
    }

    reloadRobots = async () => {
        this.checkRobotList();
        //this.forceUpdate();
    }

    render() {
        var addnewdocsign = this.state.items.indexOf('nds_robot') !== -1;
        var checkdocstatusAdd = this.state.items.indexOf('cgs_robot') !== -1;
        var getfilelink = this.state.items.indexOf('gfl_robot') !== -1;
        var createlink = this.state.items.indexOf('link_robot') !== -1;
        var resendEmails = this.state.items.indexOf('resend_robot') !== -1;

        return (
            <div className="row mt-4">
                <div className="col-12"><Trans>robot-manager</Trans></div>
                <InputContainer>
                    <RobotFieldView userField={addnewdocsign} defaultName={"user-field-add-new-doc-to-sign"} clickAdd={this.addNewDocToSign} clickRemove={this.deleteRobotAddNewDocToSign} />
                </InputContainer>
                <InputContainer>
                    <RobotFieldView userField={checkdocstatusAdd} defaultName={"user-field-check-doc-status"} clickAdd={this.checkDocStatus} clickRemove={this.deleteRobotCheckDocStatus} />
                </InputContainer>
                <InputContainer>
                    <RobotFieldView userField={getfilelink} defaultName={"user-field-get-file-link"} clickAdd={this.getFileLink} clickRemove={this.deleteGetFileLink} />
                </InputContainer>
                <InputContainer>
                    <RobotFieldView userField={createlink} defaultName={"user-field-create-link"} clickAdd={this.createLink} clickRemove={this.deleteCreateLink} />
                </InputContainer>
                <InputContainer>
                    <RobotFieldView userField={resendEmails} defaultName={"user-field-resend-emails"} clickAdd={this.resendEmails} clickRemove={this.deleteResendEmails} />
                </InputContainer>
            </div>
        );
    }
}

function RobotFieldView(props: { userField: boolean, defaultName: string, clickAdd: () => void, clickRemove: () => void }) {
    const { t } = useTranslation();
    return (
        <div className="form-check form-switch mt-2 px-0">
            <div>
                <MDBSwitch checked={props.userField} onClick={props.userField ? props.clickRemove : props.clickAdd} />
            </div>
            <div className="flex-grow-1">{t(props.defaultName)}</div>
        </div>
    );
}