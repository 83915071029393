import { Rest } from "../rest";
import ValidationError from "../service/fieldValidator/validationError";
import { SignerContacts } from './signer.contact'
import i18n from '../i18n'

export default class Contact {
    constructor(fields) {
        Object.assign(this, fields);
    }

    get Id() {
        return parseInt(this.ID) || 0;
    }

    get Link() {
        return `https://${Rest.getDomain()}/crm/contact/details/${this.Id}/`;
    }

    get FullName() {
        if (this.NAME && this.NAME.length > 0) {
            if (this.SECOND_NAME && this.SECOND_NAME.length > 0) {
                if (this.LAST_NAME && this.LAST_NAME.length > 0) {
                    return this.NAME + ' ' + this.SECOND_NAME + ' ' + this.LAST_NAME;
                }
                return this.NAME + ' ' + this.SECOND_NAME;
            }
            if (this.LAST_NAME && this.LAST_NAME.length > 0) {
                return this.NAME + ' ' + this.LAST_NAME;
            }
            return this.NAME;
        }

        if (this.EMAIL && this.EMAIL.length > 0)
            return this.EMAIL[0]?.VALUE ?? this.EMAIL;

        return 'ID: ' + this.Id;
    }

    get Email() {
        return this.EMAIL?.[0].VALUE
    }

    validateAsSigner(contact, contactError = false) {
        const _ = this;
        const errors = [];

        if (contact === SignerContacts.EMAIL.id && !_.EMAIL?.some((e) => e.VALUE_TYPE === 'WORK')) {
            errors.push(new ValidationError('field-email', { field: 'Y', warning: contactError ? 'N' : 'Y' }));
        }

        if (!_.LAST_NAME || _.LAST_NAME.length <= 0) {
            errors.push(new ValidationError('field-last-name', { field: 'Y' }));
        }

        if (!_.NAME || _.NAME.length <= 0) {
            errors.push(new ValidationError('field-first-name', { field: 'Y' }));
        }

        if (!_.SECOND_NAME || _.SECOND_NAME.length <= 0) {
            errors.push(new ValidationError('field-second-name', { field: 'Y', warning: 'Y' }));
        }

        if (contact === SignerContacts.PHONE.id) {
            const phone = _.PHONE?.find((e) => e.VALUE_TYPE === 'WORK')?.VALUE;
            if (!phone) {
                errors.push(new ValidationError('field-mobile-phone', { field: 'Y', warning: contactError ? 'N' : 'Y' }));
            } else if (!/^\+7\d{10}$/.test(phone)) {
                errors.push(new ValidationError('field-mobile-phone', { format: i18n.t('signer-phone-format'), warning: contactError ? 'N' : 'Y' }));
            }
        }

        if (contact === SignerContacts.VK.id) {
            const vkId = _.IM?.find((e) => e.VALUE_TYPE === 'VK')?.VALUE;
            if (!vkId) {
                errors.push(new ValidationError(SignerContacts.VK.labelCode, { field: 'Y', warning: contactError ? 'N' : 'Y' }));
            } else if (isNaN(Number(vkId))) {
                errors.push(new ValidationError(SignerContacts.VK.labelCode, { format: i18n.t('signer-im-format'), warning: contactError ? 'N' : 'Y' }));
            }
        }

        if (contact === SignerContacts.TELEGRAM.id) {//!_.PERSONAL_MOBILE || _.PERSONAL_MOBILE.length <= 0
            const tgId = _.IM?.find((e) => e.VALUE_TYPE === 'TELEGRAM')?.VALUE;
            if (!tgId) {
                errors.push(new ValidationError(SignerContacts.TELEGRAM.labelCode, { field: 'Y', warning: contactError ? 'N' : 'Y' }));
            } else if (isNaN(Number(tgId))) {
                errors.push(new ValidationError(SignerContacts.TELEGRAM.labelCode, { format: i18n.t('signer-im-format'), warning: contactError ? 'N' : 'Y' }));
            }
        }
        return errors;
    }

    getContactByType(type) {
        switch (type) {
            case SignerContacts.PHONE.id:
                return this.PHONE?.find((e) => e.VALUE_TYPE === 'WORK')?.VALUE;
            case SignerContacts.VK.id:
            case SignerContacts.TELEGRAM.id:
                return this.IM?.find((e) => e.VALUE_TYPE === type.toUpperCase())?.VALUE;
            default:
                return this.EMAIL?.find((e) => e.VALUE_TYPE === 'WORK')?.VALUE;
        }
    }
}