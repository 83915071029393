import React from "react";
import { AppContext } from '../context/app.context';
import { DataStorage } from '../enum/dataStorage';
import { Rest } from '../rest';
import { Trans } from 'react-i18next'
import { HomeRoutesPath } from './homeRoutes'
import i18n from '../i18n'

export default class UpdatePage extends React.Component {
    static contextType = AppContext;

    constructor({ currentVersion, currentUser }) {
        super();
        this.keys = [
            'settings',
            'PropSettingsValue',
            'log',
            'PropLogType',
            'PropLogDate',
            'PropLogUser',
            'PropLogObjectId',
            'PropLogObjectType',
            'PropLogDescription',
            'document',
            'PropDocumentResponsible',
            'PropDocumentDeal',
            'PropDocumentSigner',
            'PropDocumentSignerCounterparty',
            'PropDocumentStatus',
            'PropDocumentStatusComment',
            'PropDocumentSignatures',
            'PropDocumentOriginalFile',
            'PropDocumentOriginalFileName',
            'PropDocumentOriginalFileType',
            'PropDocumentOriginalFileId',
            'PropDocumentSignedFile',
            'PropDocumentSignedFileName',
            'PropDocumentSignedFileType',
            'PropDocumentSignedFileId',
            'PropDocumentReportFile',
            'PropDocumentReportFileName',
            'PropDocumentReportFileType',
            'PropDocumentReportFileId',
            'PropDocumentAgreementFile',
            'PropDocumentAgreementFileName',
            'PropDocumentAgreementFileType',
            'PropDocumentAgreementFileId',
            'PropDocumentSignDate',
            'PropDocumentAdditionalAgreementNumber',
            'PropDocumentAdditionalAgreementDate',
            'user',
            'PropUserUserId',
            'PropUserEmail',
            'PropUserResponsible',
            'PropUserResponsibleSearch',
            'filter',
            'PropFilterTable',
            'PropFilterData',
            'PropFilterDefault',
            'PropFilterUser',
            'filtersort',
            'PropFilterSortTable',
            'PropFilterSortData',
            'PropFilterSortUser',
            'TriggersAdd',
            'PropDocumentSignerCounterparties',
            'PropDocumentSignerStatus',
            'PropDocumentSignerContacts',
            'updateDocumentEntity',
            'PropLogActor',
            'updateLogEntity',
            'PropDocumentTransactionId',
            'addTransactionId',
            'createLinkRobot',
            'createResendRobot',
            'updateLinkRobot',
            'updateResendRobot',
            'PropDocumentAutoComplete',
            'addAutoCompleteProp',
            'updateSendDocumentRobot',
            'PropDocumentObservers',
            'addObserversProp',
            'addObserversToSendDocumentRobot',
            // should be called on every install no matter of version
            'EventKeyONAPPINSTALL',
            'EventKeyONAPPUNINSTALL',
            'PlacementDeal',
        ];
        this.eventHandler = `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}eventhandler`;
        this.events = [
            'ONAPPINSTALL',
            'ONAPPUNINSTALL'
        ];
        this.state = {
            currentCount: 0,
            allCount: this.keys.length,
            installFinished: false,
            currentVersion,
            currentUser,
            updates: {
                9: 'init',
                10: 'updateDocumentAndLogEntity',
                11: 'addTransactionId',
                12: 'createLinkRobot',
                13: 'createResendRobot',
                14: 'addSignerContacts',
                15: 'updateCreateLinkRobot',
                16: 'updateResendRobot',
                17: 'addAutoCompleteOption',
                18: 'addObservers',
                19: 'updateGetStatusRobot',
            },
            skipKeys: {
                9: 51,
                10: 5,
                11: 2,
                12: 1,
                13: 1,
                14: 1,
                15: 1,
                16: 1,
                17: 3,
                18: 3,
                19: 1,
            },
            reInstall: false,
        };
    }

    componentDidMount() {
        if (this.state.currentUser.isAdmin) {
            this.update();
        }
    }

    async update() {
        let current = Number(this.state.currentVersion) || 9;
        let skip = 0;
        for (let i = 9; i < current; i++) {
            skip += this.state.skipKeys[i];
        }
        const reInstall = !(await Rest.callMethod('app.info'))?.items?.[0]?.INSTALLED;
        this.setState({ reInstall });
        if (!reInstall) {
            skip += 3;
        }

        this.setState({ currentCount: skip });

        while (this.state.updates[current] !== undefined) {
            await this[this.state.updates[current]]();
            current++;
        }

        if (reInstall) {
            await this.bindPlacements();
            await this.bindEvents();
        }
    }

    componentDidUpdate() {
        if (this.state.installFinished) return;

        const _ = this;
        const percent = _.getPercent();
        if (percent === 100) {
            this.setState({ installFinished: true }, () => {
                _.context.setAppSettings('Version', _.context.appVersion).then(() => {
                    if (this.state.reInstall) {
                        Rest.installComplete();
                    } else {
                        _.context.updateComplete();
                    }
                });
            })
        }
    }

    getPercent() {
        const _ = this;
        let percent;
        if (_.state.allCount < 1) {
            percent = 100;
        } else {
            percent = _.state.currentCount / _.state.allCount * 100;
        }
        return percent;
    }

    addProgressKey(key) {
        console.log(`Passed: ${key}`);
        this.setState((prev) => ({ ...prev, currentCount: prev.currentCount + 1 }));
    }

    // ----- updates -----
    async init() {
        const promises = [];
        promises.push(this.createEntitySettings());
        promises.push(this.createEntityLog());
        promises.push(this.createEntityUser());
        promises.push(this.createEntityDocument());
        promises.push(this.createEntityFilter());
        promises.push(this.createEntityFilterSort());
        promises.push(this.triggersAdd());

        return Promise.all(promises);
    }

    async updateDocumentAndLogEntity() {
        await this.updateDocumentEntity();
        await this.updateLogEntity();
    }

    addTransactionId() {
        return this.createEntityProperties('addTransactionId', {
            PropDocumentTransactionId: ['entity.item.property.add', {
                ENTITY: DataStorage.document,
                PROPERTY: 'TRANSACTION_ID',
                NAME: 'transaction id',
                TYPE: 'S'
            }],
        });
    }

    async createLinkRobot(key) {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'link_robot',
                HANDLER: `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}robot/create-sign-link-handler`,
                NAME: 'Создать ссылку на документ',
                USE_SUBSCRIPTION: 'Y',
                PROPERTIES: {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    'email': {
                        'Name': 'Контакт подписанта',
                        'Type': 'string'
                    },
                },
                RETURN_PROPERTIES: {
                    'link': {
                        'Name': 'Ссылка',
                        'Type': 'string'
                    },
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                    'error': {
                        'Name': 'Отчет об ошибке',
                        'Type': 'string'
                    }
                }
            });
        } catch (e) {} finally {
            this.addProgressKey(key ?? 'createLinkRobot');
        }
    }

    async createResendRobot(key) {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'resend_robot',
                HANDLER: `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}robot/resend-emails-handler`,
                NAME: 'Повторная отправка уведомления',
                USE_SUBSCRIPTION: 'Y',
                PROPERTIES: {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    'contact_id': {
                        'Name': 'Идентификатор контакта',
                        'Type': 'string'
                    },
                },
                RETURN_PROPERTIES: {
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                }
            });
        } catch (e) {} finally {
            this.addProgressKey(key ?? 'createResendRobot');
        }
    }

    async createGetStatusRobot(key) {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'resend_robot',
                HANDLER: `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}robot/resend-emails-handler`,
                NAME: 'Повторная отправка уведомления',
                USE_SUBSCRIPTION: 'Y',
                PROPERTIES: {
                    'transaction_id': {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    'contact_id': {
                        'Name': 'Идентификатор контакта',
                        'Type': 'string'
                    },
                },
                RETURN_PROPERTIES: {
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    },
                }
            });
        } catch (e) {} finally {
            this.addProgressKey(key ?? 'createResendRobot');
        }
    }

    async createSendDocRobot(key) {
        try {
            await Rest.callMethod('bizproc.robot.add', {
                CODE: 'cgs_robot',
                HANDLER: `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}robot/checkdocstatusAddingHandler`,
                AUTH_USER_ID: 1,
                NAME: 'Проверка статуса документа',
                USE_SUBSCRIPTION: 'Y',
                'PROPERTIES': {
                    transaction_id: {
                        'Name': 'Транзакция',
                        'Type': 'string'
                    },
                    wait_for: {
                        Name: 'Ожидать статус',
                        Type: "select",
                        Options: {
                            EmailComplitedDoc: i18n.t("document-status-EmailComplitedDoc"),
                            Denied: i18n.t("document-status-Denied"),
                            Cancelled: i18n.t("document-status-Cancelled"),
                            Signed: i18n.t("document-status-Signed"),
                            AwaitingSignatures: i18n.t("document-status-AwaitingSignatures"),
                            Created: i18n.t("document-status-Created"),
                            Opened: i18n.t("document-status-Opened"),
                            Completed: i18n.t("document-status-Completed"),
                            EmailToActorSended: i18n.t("document-status-EmailToActorSended"),
                            EmailToAuthorSended: i18n.t("document-status-EmailToAuthorSended"),
                            RegisteredInAvisHash: i18n.t("document-status-RegisteredInAvisHash"),
                            AwaitingPayment: i18n.t("document-status-AwaitingPayment"),
                            AwaitingFinalization: i18n.t("document-status-AwaitingFinalization"),
                        },
                    }
                },
                RETURN_PROPERTIES: {
                    'document_id': {
                        'Name': 'ID документа',
                        'Type': 'string'
                    },
                    'agreement_id': {
                        'Name': 'ID документа доп. соглашения',
                        'Type': 'string'
                    },
                    'report_id': {
                        'Name': 'ID документа отчета о подписании',
                        'Type': 'string'
                    },
                    'document_text': {
                        'Name': 'Текст статуса документа',
                        'Type': 'string'
                    },
                    'document_status': {
                        'Name': 'Код статус документа',
                        'Type': 'string'
                    },
                    'app_text': {
                        'Name': 'Текст ответа приложения',
                        'Type': 'string'
                    },
                    'app_status': {
                        'Name': 'Код ответа приложения',
                        'Type': 'string'
                    }
                }
            });
        } catch(e) {} finally {
            this.addProgressKey(key)
        }
    }

    addSignerContacts() {
        return this.createEntityProperties('addSignerContacts', {
            PropDocumentTransactionId: ['entity.item.property.add', {
                ENTITY: DataStorage.document,
                PROPERTY: 'SIGNER_CONTACTS',
                NAME: 'signers contacts',
                TYPE: 'S'
            }],
        });
    }

    async updateCreateLinkRobot() {
        try {
            await Rest.callMethod('bizproc.robot.delete', { CODE: 'link_robot' });
        } catch (e) {}
        await this.createLinkRobot('updateCreateLinkRobot');
    }

    async updateResendRobot() {
        try {
            await Rest.callMethod('bizproc.robot.delete', { CODE: 'resend_robot' });
        } catch (e) {}
        await this.createResendRobot('updateResendRobot');
    }

    async updateGetStatusRobot() {
        try {
            await Rest.callMethod('bizproc.robot.delete', { CODE: 'cgs_robot' });
        } catch (e) {}
        await this.createResendRobot('updateGetStatusRobot');
    }

    async addAutoCompleteOption() {
        await this.addAutoCompleteProp();
        await this.updateSendDocumentRobot();
    }

    async addObservers() {
        await this.addObserversProp();
        await this.addObserversToSendDocumentRobot();
    }
    // -------------------

    async createEntitySettings() {
        const storageId = DataStorage.settings;
        const propData = {
            PropSettingsValue: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'VALUE', NAME: 'value', TYPE: 'S' }]
        };
        return this.createEntity(storageId, propData);
    }

    createEntityLog() {
        const _ = this;
        const storageId = DataStorage.log;

        const propData = {
            PropLogType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'TYPE', NAME: 'type', TYPE: 'S' }],
            PropLogDate: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'DATE', NAME: 'date', TYPE: 'N' }],
            PropLogUser: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'USER', NAME: 'user', TYPE: 'N' }],
            PropLogObjectId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'OBJECT_ID', NAME: 'object ID', TYPE: 'S' }],
            PropLogObjectType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'OBJECT_TYPE', NAME: 'object type', TYPE: 'S' }],
            PropLogDescription: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'DESCRIPTION', NAME: 'descrpiption', TYPE: 'S' }]
        };
        return _.createEntity(storageId, propData);
    }

    async triggersAdd() {
        const triggerList = await Rest.callMethod('crm.automation.trigger.list', {});
        if (triggerList.items.length === 0) {
            const batchData = {
                0 : [
                    'crm.automation.trigger.add',
                    {
                        CODE: 'trigger_doc_end',
                        NAME: 'Триггер: документооборот завершен'
                    }
                ],
                1 : [
                    'crm.automation.trigger.add',
                    {
                        CODE: 'trigger_doc_del',
                        NAME: 'Триггер: отказ'     
                    }
                ]
            };

            var checkError = true;
            Rest.callBatch(batchData, (batchResult) => {
                Object.keys(batchResult).forEach(key => {
                    const eventResult = batchResult[key];
                    if (eventResult.error()) {
                        console.error('error-triggers-add', eventResult.error());
                        checkError = false;
                    }
                });
                if (checkError) {
                    this.addProgressKey('TriggersAdd');
                }
            });
        }
        else {
            this.addProgressKey('TriggersAdd');
        }
    }

    createEntityFilter() {
        const _ = this;
        const storageId = DataStorage.filter;

        const propData = {
            PropFilterTable: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'TABLE', NAME: 'table id', TYPE: 'S' }],
            PropFilterData: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'DATA', NAME: 'data', TYPE: 'S' }],
            PropFilterDefault: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'DEFAULT', NAME: 'default', TYPE: 'S' }],
            PropFilterUser: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'USER', NAME: 'user', TYPE: 'S' }]
        };

        return _.createEntity(storageId, propData);
    }

    createEntityFilterSort() {
        const _ = this;
        const storageId = DataStorage.filterSort;

        const propData = {
            PropFilterSortTable: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'TABLE', NAME: 'table id', TYPE: 'S' }],
            PropFilterSortData: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'DATA', NAME: 'data', TYPE: 'S' }],
            PropFilterSortUser: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'USER', NAME: 'user', TYPE: 'S' }]
        };

        return _.createEntity(storageId, propData);
    }

    createEntityDocument() {
        const _ = this;
        const storageId = DataStorage.document;

        const propData = {
            PropDocumentResponsible: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'RESPONSIBLE', NAME: 'responsible', TYPE: 'S' }],
            PropDocumentDeal: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'DEAL', NAME: 'deal', TYPE: 'S' }],
            PropDocumentSigner: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNER', NAME: 'signer', TYPE: 'S' }],
            PropDocumentSignerCounterparty: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNER_COUNTERPARTY', NAME: 'counterparty signer', TYPE: 'S' }],
            PropDocumentStatus: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'STATUS', NAME: 'status', TYPE: 'S' }],
            PropDocumentStatusComment: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'STATUS_COMMENT', NAME: 'status comment', TYPE: 'S' }],
            PropDocumentSignatures: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNATURES', NAME: 'signatures', TYPE: 'S' }],
            PropDocumentOriginalFile: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'ORIGINAL_FILE', NAME: 'original file', TYPE: 'F' }],
            PropDocumentOriginalFileName: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'ORIGINAL_FILE_NAME', NAME: 'original filename', TYPE: 'S' }],
            PropDocumentOriginalFileType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'ORIGINAL_FILE_TYPE', NAME: 'original file type', TYPE: 'S' }],
            PropDocumentOriginalFileId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'ORIGINAL_FILE_ID', NAME: 'original file id', TYPE: 'S' }],
            PropDocumentSignedFile: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNED_FILE', NAME: 'signed file', TYPE: 'F' }],
            PropDocumentSignedFileName: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNED_FILE_NAME', NAME: 'signed filename', TYPE: 'S' }],
            PropDocumentSignedFileType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNED_FILE_TYPE', NAME: 'signed file type', TYPE: 'S' }],
            PropDocumentSignedFileId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGNED_FILE_ID', NAME: 'signed file id', TYPE: 'S' }],
            PropDocumentReportFile: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'REPORT_FILE', NAME: 'report file', TYPE: 'F' }],
            PropDocumentReportFileName: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'REPORT_FILE_NAME', NAME: 'report filename', TYPE: 'S' }],
            PropDocumentReportFileType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'REPORT_FILE_TYPE', NAME: 'report file type', TYPE: 'S' }],
            PropDocumentReportFileId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'REPORT_FILE_ID', NAME: 'report file id', TYPE: 'S' }],
            PropDocumentAgreementFile: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'AGREEMENT_FILE', NAME: 'agreement file', TYPE: 'F' }],
            PropDocumentAgreementFileName: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'AGREEMENT_FILE_NAME', NAME: 'agreement filename', TYPE: 'S' }],
            PropDocumentAgreementFileType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'AGREEMENT_FILE_TYPE', NAME: 'agreement file type', TYPE: 'S' }],
            PropDocumentAgreementFileId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'AGREEMENT_FILE_ID', NAME: 'agreement file id', TYPE: 'S' }],
            PropDocumentSignDate: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'SIGN_DATE', NAME: 'sign date', TYPE: 'N' }],
            PropDocumentAdditionalAgreementNumber: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'ADDITIONAL_AGREEMENT_DOCUMENT_NUMBER', NAME: 'additional agreement date number', TYPE: 'S' }],
            PropDocumentAdditionalAgreementDate: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'ADDITIONAL_AGREEMENT_DOCUMENT_DATE', NAME: 'additional agreement date', TYPE: 'S' }]
        };

        return _.createEntity(storageId, propData);
    }

    createEntityUser() {
        const _ = this;
        const storageId = DataStorage.user;

        const propData = {
            PropUserUserId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'USER', NAME: 'user id', TYPE: 'S' }],
            PropUserEmail: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'EMAIL', NAME: 'email', TYPE: 'S' }],
            PropUserResponsible: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'RESPONSIBLE', NAME: 'Responsible', TYPE: 'S' }],
            PropUserResponsibleSearch: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'RESPONSIBLE_SEARCH', NAME: 'Responsible Search', TYPE: 'S' }]
        };
        
        return _.createEntity(storageId, propData);
    }

    async bindEvents() {
        const _ = this;
        if (!_.eventHandler) {
            _.events.forEach((x) => {
                _.addProgressKey(`EventKey${x}`);
            });
            return;
        }

        try {
            const eventCopies = _.events.slice();
            const bindedEvents = await Rest.callMethod('event.get', {}, true);
            bindedEvents.items.forEach(eventItem => {
                const index = eventCopies.findIndex(x => eventItem.event === x);
                if (index > -1) {
                    eventCopies.slice(index, 1);
                    _.addProgressKey(`EventKey${eventItem.event}`);
                }
            });

            if (eventCopies.length > 0) {
                const batchData = {};
                eventCopies.forEach(x => {
                    batchData[`EventKey${x}`] = ['event.bind', { event: x, handler: _.eventHandler }]
                });

                Rest.callBatch(batchData, (batchResult) => {
                    const keys = Object.keys(batchResult);
                    keys.forEach(key => {
                        const eventResult = batchResult[key];
                        if (eventResult.error()) {
                            console.error('error-event-bind', eventResult.error());
                        }
                        else {
                            _.addProgressKey(key);
                        }
                    });
                });
            }
        }
        catch (err) {
            console.error('event.get', err);
        }
    }

    bindPlacements() {
        const _ = this;
        Rest.callMethod('placement.get').then(placements => {
            const dealTab = placements.items.find(x => x.placement === 'CRM_DEAL_DETAIL_TAB');
            if (dealTab) {
                _.addProgressKey('PlacementDeal');
            }
            else {
                _.bindPlacementDeal();
            }
        }).catch(err => {
            const error = err.ex ? err.ex : err;
            if (error.error === 'ACCESS_DENIED') {
                console.warn('cant get placement list');
                _.addProgressKey('PlacementDeal');
            }
            else {
                console.error('cant get placement list', err);
            }
        });
    }

    bindPlacementDeal() {
        const _ = this;
        Rest.callMethod('placement.bind', {
            PLACEMENT: 'CRM_DEAL_DETAIL_TAB',
            HANDLER: `${HomeRoutesPath.ServerUrl}${HomeRoutesPath.Prefix}deal`,
            TITLE: 'SIGNX ЭЦП'
        }).then(res => {
            _.addProgressKey('PlacementDeal');
        }).catch(err => {
            const error = err.ex ? err.ex : err;
            if (error.error === 'ACCESS_DENIED') {
                console.warn('cant bind placement CRM_DEAL_DETAIL_TAB');
                _.addProgressKey('PlacementDeal');
            }
            else {
                console.error('cant bind placement CRM_DEAL_DETAIL_TAB', err);
            }
        });
    }

    updateDocumentEntity() {
        return this.createEntityProperties('updateDocumentEntity', {
            PropDocumentSignerCounterparties: ['entity.item.property.add', {
                ENTITY: DataStorage.document,
                PROPERTY: 'SIGNER_COUNTERPARTIES',
                NAME: 'additional counterparties',
                TYPE: 'S'
            }],
            PropDocumentSignerStatus: ['entity.item.property.add', {
                ENTITY: DataStorage.document,
                PROPERTY: 'SIGNER_STATUS',
                NAME: 'signer status',
                TYPE: 'S'
            }],
        });
    }

    updateLogEntity() {
        return this.createEntityProperties('updateLogEntity', {
            PropLogActor: ['entity.item.property.add', {
                ENTITY: DataStorage.log,
                PROPERTY: 'ACTOR',
                NAME: 'actor email',
                TYPE: 'S'
            }],
        });
    }

    async createEntity(storageId, properties) {
        try {
            await Rest.callMethod('entity.add', { ENTITY: storageId, NAME: storageId, ACCESS: { AU: 'X' } });
            return await this.createEntityProperties(storageId, properties);
        } catch (e) {
            const error = e.ex ? e.ex : e;
            if (error.error !== 'ERROR_ENTITY_ALREADY_EXISTS') {
                console.error('error-add-storage', storageId, error);
            } else {
                return this.createEntityProperties(storageId, properties);
            }
        }
    }

    async createEntityProperties(storageId, properties) {
        const result = await Rest.callBatchAsync(properties);
        for (const key in result) {
            if (key.startsWith('Prop')) {
                const r = result[key];
                if (r.error()) {
                    const err = r.error().error ? r.error() : r.error().ex;
                    if (err.error !== 'ERROR_PROPERTY_ALREADY_EXISTS') {
                        console.error(key, err, r.error());
                    }
                }
                await this.addProgressKey(key);
            }
        }
        return this.addProgressKey(storageId);
    }

    addAutoCompleteProp() {
        return this.createEntityProperties('addAutoCompleteProp', {
            PropDocumentAutoComplete: ['entity.item.property.add', {
                ENTITY: DataStorage.document,
                PROPERTY: 'AUTO_COMPLETE',
                NAME: 'autoComplete',
                TYPE: 'S'
            }],
        });
    }

    async updateSendDocumentRobot() {
        try {
            await Rest.callMethod('bizproc.robot.delete', { CODE: 'nds_robot' });
        } catch (e) {}
        await this.createSendDocRobot('updateSendDocumentRobot');
    }

    addObserversProp() {
        return this.createEntityProperties('addObserversProp', {
            PropDocumentObservers: ['entity.item.property.add', {
                ENTITY: DataStorage.document,
                PROPERTY: 'OBSERVERS',
                NAME: 'observers',
                TYPE: 'S'
            }],
        });
    }

    async addObserversToSendDocumentRobot() {
        try {
            await Rest.callMethod('bizproc.robot.delete', { CODE: 'nds_robot' });
        } catch (e) {}
        await this.createSendDocRobot('addObserversToSendDocumentRobot');
    }

    render() {
        return (
            <div>
                {this.state.currentUser.isAdmin && <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: this.getPercent() }}></div>
                </div>}
                {!this.state.currentUser.isAdmin && <Trans>update-not-admin</Trans>}
            </div>
        );
    }
}