import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import DocumentTable from '../component/table/documentTable';
import Filter from '../component/filter/filter';
import FilterItem from '../component/filter/filterItem';
import FilterType from '../component/filter/filterType';
import { Rest } from '../rest';
import { AppContext } from '../context/app.context';
import i18next from 'i18next'
import i18n from '../i18n'

export default class MainPage extends React.Component {
    static contextType = AppContext;

    constructor() {
        super();
        const info = Rest.placementInfo();
        const dealId = info.placement !== 'CRM_DEAL_DETAIL_TAB' || !info.options.ID ? false : info.options.ID;
        this.state = {
            dealId: dealId
        };
        this.filterItems = [
            new FilterItem(FilterType.Hidden, 'property_original_file_name', 'field-title', { Typing: 'Y', PREFIX: 'Y' }),
            new FilterItem(FilterType.Select, 'property_status', 'field-status', { Default: 'Y', Items: [
                    { value: 'draft', label: i18n.t('document-status-draft') },
                    { value: 'queue', label: i18n.t('document-status-queue') },
                    { value: 'sendError', label: i18n.t('document-status-sendError') },
                    { value: 'AwaitingPayment', label: i18n.t('document-status-AwaitingPayment') },
                    { value: 'AwaitingFinalization', label: i18n.t('document-status-AwaitingFinalization') },
                    { value: 'AwaitingSignatures', label: i18n.t('document-status-AwaitingSignatures') },
                    { value: 'Created', label: i18n.t('document-status-Created') },
                    { value: 'Opened', label: i18n.t('document-status-Opened') },
                    { value: 'Signed', label: i18n.t('document-status-Signed') },
                    { value: 'Denied', label: i18n.t('document-status-Denied') },
                    { value: 'Cancelled', label: i18n.t('document-status-Cancelled') },
                    { value: 'Completed', label: i18n.t('document-status-Completed') },
                    { value: 'EmailToActorSended', label: i18n.t('document-status-EmailToActorSended') },
                    { value: 'EmailToAuthorSended', label: i18n.t('document-status-EmailToAuthorSended') },
                    { value: 'RegisteredInAvisHash', label: i18n.t('document-status-RegisteredInAvisHash') },
                    { value: 'EmailComplitedDoc', label: i18n.t('document-status-EmailComplitedDoc') },
                ] }),
            new FilterItem(FilterType.UserSelect, 'property_responsible', 'field-responsible', { Default: 'Y' }),
            new FilterItem(FilterType.UserSelect, 'property_signer', 'field-signer', { Default: 'Y' }),
            new FilterItem(FilterType.ContactSelect, 'property_signer_counterparty', 'field-signer-counterparty', { Default: 'Y' }),
            new FilterItem(FilterType.DateRange, 'property_sign_date', 'field-sign-date', { NUMBER: 'Y' })
        ];

        if (!this.state.dealId) {
            this.filterItems.push(new FilterItem(FilterType.DealSelect, 'property_deal', 'field-communication-crm', { Default: 'Y' }));
        }

        this.table = React.createRef();
        this.filter = React.createRef();
    }

    componentDidMount() {
        const info = Rest.placementInfo();
        if (info.options.document) {
            const link = `/document/${info.options.document}`;
            Rest.removePlacementOption('document');
            this.props.history.push(link);
        }
        if (this.state.dealId) {
            this.context.setHeaderVisibility(false);
        }
    }

    refreshTable = () => {
        if (this.table && this.table.current) {
            this.table.current.setState({ currentPage: 1 }, () => {
                this.table.current.tryLoadData();
            });
        }
    }

    // clickEditMessage = (e, item) => {
    //     if (e) e.preventDefault();
    //     const _ = this;

    //     console.log('clickEditMessage', item);
    //     var result = prompt("new msg", item.PROPERTY_VALUES.STATUS_COMMENT);

    //     if (!result || result.length < 1) {
    //         console.log('cancel');
    //         return;
    //     }
    //     console.log('add', result);

    //     Rest.callMethod('entity.item.update', {
    //         ID: item.ID,
    //         ENTITY: DataStorage.document,
    //         PROPERTY_VALUES: {
    //             STATUS_COMMENT: result
    //         }
    //     }).then(() => {
    //         _.refreshTable();
    //     });
    // }

    render() {
        return (
            <div>
                <div className="d-xl-flex mb-3 mt-3">
                    <div className="app-filter-actions mb-2 mb-xl-0">
                        <Link className="btn btn-success px-3 m-0 me-2 text-nowrap" to="/document/0"><i class="fas fa-plus me-2"></i><Trans>document-item</Trans></Link>
                    </div>
                    <Filter ref={this.filter} id={this.state.dealId ? 'filter-documents-deal' : 'filter-documents'} items={this.filterItems} refreshTable={this.refreshTable} />
                </div>
                <DocumentTable ref={this.table} filter={this.filter} dealId={this.state.dealId} />
            </div>
        );
    }
}