import React from "react";
import { Trans } from "react-i18next";
import { Rest } from "../rest";

interface IProps {
}

interface IState {
    items: string[],
    error: string
}

export default class TriggerAdding extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            items: [],
            error: ''
        };
    }

    componentDidMount() {
        this.checkTriggerList();
    }
    clickTriggerEnd = async () => {
        var triggerEnd = false;
        this.state.items.forEach(function(value: any, key: any) {
            if (value['CODE'] === 'trigger_doc_end') {
                triggerEnd = true;
            }
        });
        if (!triggerEnd) {
            this.addNewTriggerEnd();
        }
        else {
            this.deleteTriggerEnd();
        }
    }
    clickTriggerDelete = async () => {
        var triggerDel = false;
        this.state.items.forEach(function(value: any, key: any) {
            if (value['CODE'] === 'trigger_doc_del') {
                triggerDel = true;
            }
        });
        if (!triggerDel) {
            this.addNewTriggerDelete();
        }
        else {
            this.deleteTriggerDelete();
        }
    }
    addNewTriggerEnd = async () => {
        try {
            await Rest.callMethod('crm.automation.trigger.add', {
                CODE: 'trigger_doc_end',
                NAME: 'Триггер: документооборот завершен'
            });
            this.reloadTriggers();
        }
        catch (err) {
            console.log('error adding trigger', err);
        }
    }
    addNewTriggerDelete = async () => {
        try {
            await Rest.callMethod('crm.automation.trigger.add', {
                CODE: 'trigger_doc_del',
                NAME: 'Триггер: отказ'
            });
            this.reloadTriggers();
        }
        catch (err) {
            console.log('error adding trigger', err);
        }
    }

    checkTriggerList = async () => {
        try {
            const result = await Rest.callMethod('crm.automation.trigger.list');
            this.setState({
                items: result.items
            });
            Rest.resizeFrame();
        }
        catch (err) {
            console.log(`checkTriggerList`, err);
        }
    }

    deleteTriggerEnd = async () => {
        try {
            await Rest.callMethod('crm.automation.trigger.delete', {
                CODE: 'trigger_doc_end'
            });
            this.reloadTriggers();
        }
        catch (err) {
            console.log(`deleteTriggerEnd`, err);
        }
    }
    deleteTriggerDelete = async () => {
        try {
            await Rest.callMethod('crm.automation.trigger.delete', {
                CODE: 'trigger_doc_del'
            });
            this.reloadTriggers();
        }
        catch (err) {
            console.log(`deleteTriggerDelete`, err);
        }
    }

    reloadTriggers = async () => {
        this.checkTriggerList();
    }

    render() {
        var triggerEnd = false;
        var triggerDel = false;
        this.state.items.forEach(function(value: any, key: any) {
            if (value['CODE'] === 'trigger_doc_end') {
                triggerEnd = true;
            }
            else if (value['CODE'] === 'trigger_doc_del') {
                triggerDel = true;
            }
        });

        return (
            <div className="row mt-4">
                <div className="col-12"><Trans>settings-notifications-triggers</Trans></div>
                <div className="col-12 col-lg-8 col-xl-7">
                    <div className="form-check form-switch mt-2">
                        <input className="form-check-input" type="checkbox" checked={triggerEnd} onChange={this.clickTriggerEnd} />
                        <label className="form-check-label" onClick={this.clickTriggerEnd}><Trans>trigger-name-end</Trans></label>
                    </div>
                </div>
                <div className="col-12 col-lg-8 col-xl-7">
                    <div className="form-check form-switch mt-2">
                        <input className="form-check-input" type="checkbox" checked={triggerDel} onChange={this.clickTriggerDelete} />
                        <label className="form-check-label" onClick={this.clickTriggerDelete}><Trans>trigger-name-delete</Trans></label>
                    </div>
                </div>
            </div>
        );
    }
}