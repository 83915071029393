import React from "react";
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import { MDBDropdownLink } from 'mdb-react-ui-kit';
import { DataStorage } from "../../enum/dataStorage";
import { Rest } from "../../rest";
import TableComponent from './tableComponent';
import LogService from "../../service/logService";
import { LogType } from "../../enum/logType";
import { ObjectType } from "../../enum/objectType";
import { AppContext } from "../../context/app.context";
import UserSignData from "../../models/userSignData";

class UserTable extends TableComponent {
    static contextType = AppContext;

    constructor() {
        super();
        this.Id = 'user-table-component';
        this.state.sortBy = 'id';
        this.state.visibleColumns = ['property_user', 'property_responsible', 'property_email'];
        var currentDefinition = this;

        /*
        this.Header.push({
            getHeaderObject: function () {
                return currentDefinition.getSelectAllCell();
            },
            getCellObject: function (item) {
                return currentDefinition.getSelectRowCell(item.Id);
            }
        });*/

        this.Header.push({
            getCellObject: function (item) {
                return currentDefinition.getDropdownCell(currentDefinition.getDropdownActions(item));
            }
        });

        this.Header.push({
            text_id: 'field-user',
            field: 'property_user',
            sortable: true,
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.User &&
                            <Link to={`/user/${item.User.Id}`}>
                                {item.User.Photo &&
                                    <span className="tasks-grid-avatar" style={{ backgroundImage: `url(${item.User.Photo})` }}></span>
                                }
                                {item.User.FullName}
                            </Link>
                        }
                    </td>
                );
            }
        });

        this.Header.push({
            text_id: 'field-responsibles',
            field: 'property_responsible',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.Responsibles.map(x => (
                            <>{x.LinkIconView}</>
                        ))}
                    </td>
                )
            }
        })

        this.Header.push({
            text_id: 'field-email',
            field: 'property_email',
            getCellObject: function (item) {
                return (
                    <td className="align-middle">
                        {item.Email}
                    </td>
                );
            }
        });
    }

    loadData = async () => {
        const currentDefinition = this;
        const sortBy = currentDefinition.state.sortBy.toUpperCase();
        const sortOrder = currentDefinition.state.sortOrder.toUpperCase();
        var requestData = {
            ENTITY: DataStorage.user,
            SORT: {},
            filter: {},
            start: currentDefinition.getOffset()
            //take: currentDefinition.getStep()
        };
        requestData.SORT[sortBy] = sortOrder;
        currentDefinition.applyFilter(requestData.filter);

        try {
            const result = await Rest.callMethod('entity.item.get', requestData);
            const items = result.items.map(x => new UserSignData(x));
            const userIds = [];
            items.forEach((item) => {
                if (item.UserId > 0 && !userIds.includes(item.UserId)) {
                    userIds.push(item.UserId);
                }
                const responsibleId = item.ResponsibleId;
                if (responsibleId) {
                    responsibleId.forEach(userId => {
                        if (!userIds.includes(userId)) {
                            userIds.push(userId);
                        }
                    });
                }
            });
            const users = await Rest.getUsers(userIds);
            items.forEach(item => {
                if (users[item.UserId]) {
                    item.User = users[item.UserId];
                }
                item.Responsibles = [];
                const responsibleId = item.ResponsibleId;
                if (responsibleId) {
                    responsibleId.forEach(userId => {
                        if (users[userId]) {
                            item.Responsibles.push(users[userId]);
                        }
                    });
                }
            });
            currentDefinition.printRows(items, result.total);
        }
        catch (err) {
            currentDefinition.loadDataError(err);
        }
        finally {
            currentDefinition.loadDataAlways();
        }

        /*
        Rest.callMethod('entity.item.get', requestData)
            .then((result) => {
                const items = result.items.map(x => { return { ...x, Id: parseInt(x.ID) || 0, UserId: parseInt(x.PROPERTY_VALUES.USER) || 0 } });
                const userIds = [];
                items.forEach((item) => {
                    if (item.UserId > 0 && !userIds.includes(item.UserId)) {
                        userIds.push(item.UserId);
                    }
                });
                Rest.getUsers(userIds, (users) => {
                    items.forEach(item => {
                        if (users[item.UserId]) {
                            item.User = users[item.UserId];
                        }
                    });
                    currentDefinition.printRows(items, result.total);
                    currentDefinition.loadDataAlways();
                });
            })
            .catch((err) => {
                currentDefinition.loadDataError(err);
                currentDefinition.loadDataAlways();
            });*/
    }

    clickEdit = (e, item) => {
        if (e) e.preventDefault();
        window.location.href = `/user/${item.User.Id}`;
    }

    clickDelete = (e, item) => {
        const _ = this;
        if (e) e.preventDefault();
        Rest.callMethod('entity.item.delete', { ENTITY: DataStorage.user, ID: item.Id }).then(() => {
            new LogService(LogType.UserDelete, _.context.getCurrentUser().Id, item.UserId, ObjectType.user).add();
            _.loadData();
        }).catch(err => {
            console.error('clickDelete user', err);
        });
    }

    getDropdownActions = (item) => {
        const currentDefinition = this;
        const btnEdit = (
            <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickEdit(e, item)}>
                <Trans>btn-edit</Trans>
            </MDBDropdownLink>
        );
        const btnDelete = (
            <MDBDropdownLink tag="button" onClick={(e) => currentDefinition.clickDelete(e, item)}>
                <Trans>btn-delete</Trans>
            </MDBDropdownLink>
        );
        if (item.User) {
            return [btnEdit, btnDelete];
        }
        return [btnDelete];
    }
}

export default UserTable;