import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { MDBInput } from 'mdb-react-ui-kit';
import i18next from 'i18next';
import { Rest } from '../rest';
import { AppContext } from '../context/app.context';
import UserSelect from "../component/select/user";
import DomainAuthorization from "../component/domainAuthorization";
import { DocumentStatus } from "../enum/documentStatus";
import TriggerAdding from "../component/triggerAdding";
import RobotAdding from "../component/robotAdding";

export default class SettingsPage extends React.Component {
    static contextType = AppContext;

    constructor() {
        super();
        this.state = {
            isLoaded: false,
            loading: true,
            admins: [],
            externalSystemClientId: '',
            notifyResponsible: false,
            notifySigner: false,
            subscriptions: [],
            statuses: Object.keys(DocumentStatus)
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    async refreshData() {
        this.setState({
            loading: true
        });

        const users = await Rest.getUsers(this.context.settings.AdminList.Value);
        const admins = [];

        this.context.settings.AdminList.Value.forEach(id => {
            if (users[id]) {
                admins.push({ value: users[id].Id, label: users[id].FullName });
            }
        });
        this.setState({
            externalSystemClientId: this.context.settings.ExternalSystemClientId.Value,
            notifyResponsible: this.context.settings.NotifyResponsible.Value === 'Y',
            notifySigner: this.context.settings.NotifySigner.Value === 'Y',
            subscriptions: this.context.settings.NotificationSubscriptions.Value,
            loading: false,
            admins: admins
        }, () => {
            Rest.resizeFrame()
        });
    }

    onAdminsChange = (selected) => {
        this.setState({
            admins: selected
        });
    }

    onChangeNotifyResponsible = (e) => {
        this.setState({
            notifyResponsible: e.target.checked
        });
    }

    onChangeNotifySigner = (e) => {
        this.setState({
            notifySigner: e.target.checked
        });
    }

    onChangeExternalSystemClientId = (e) => {
        this.setState({
            externalSystemClientId: e.target.value
        });
    }

    onChangeSubscription = (item) => {
        const { subscriptions } = this.state;
        if (subscriptions.includes(item)) {
            this.setState({
                subscriptions: subscriptions.filter(x => x !== item)
            });
        }
        else {
            subscriptions.push(item);
            this.setState({
                subscriptions: subscriptions
            });
        }
    }

    onSubmit = async (e) => {
        if (e) e.preventDefault();
        const _ = this;
        const adminIds = _.state.admins.map(x => x.value);

        if (adminIds.length > 0 && !adminIds.includes(_.context.getCurrentUser().Id)) {
            adminIds.push(_.context.getCurrentUser().Id);
        }
        await _.context.setAppSettings('ExternalSystemClientId', _.state.externalSystemClientId);
        await _.context.setAppSettings('NotifyResponsible', _.state.notifyResponsible ? 'Y' : 'N');
        await _.context.setAppSettings('NotifySigner', _.state.notifySigner ? 'Y' : 'N');
        await _.context.setAppSettings('NotificationSubscriptions', _.state.subscriptions);
        await _.context.setAppSettings('AdminList', adminIds);
        await _.context.initAppSettings();
        _.refreshData();
    }

    render() {
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-8 col-xl-7 col-xxl-6">
                            <div><Trans>field-administrators</Trans></div>
                            <div className="form-outline">
                                <UserSelect value={this.state.admins} onChange={this.onAdminsChange} isMulti={true} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-8 col-xl-7 col-xxl-6">
                            <MDBInput type="text" value={this.state.externalSystemClientId} onChange={this.onChangeExternalSystemClientId} label={i18next.t('field-externalSystemClientId')} required />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-8 col-xl-7 col-xxl-6">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="notify-resposible" checked={this.state.notifyResponsible} onChange={this.onChangeNotifyResponsible} />
                                <label class="form-check-label" for="notify-resposible"><Trans>field-notify-resposible</Trans></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-8 col-xl-7 col-xxl-6">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="notify-signer" checked={this.state.notifySigner} onChange={this.onChangeNotifySigner} />
                                <label class="form-check-label" for="notify-signer"><Trans>field-notify-signer</Trans></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12"><Trans>settings-notifications-subscription</Trans></div>
                        {this.state.statuses.map(x => {
                            const item = DocumentStatus[x];
                            return (
                                <div key={item} className="col-12 col-lg-8 col-xl-7">
                                    <div class="form-check form-switch mt-2">
                                        <input class="form-check-input" type="checkbox" id={`subscription-${item}`} checked={this.state.subscriptions.includes(item)} onChange={() => this.onChangeSubscription(item)} />
                                        <label class="form-check-label" for={`subscription-${item}`}><Trans>document-status-{item}</Trans></label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <TriggerAdding />
                    <RobotAdding />
                    {!this.loading &&
                        <div className="mt-4">
                            <button type="submit" className="btn btn-primary me-2"><Trans>btn-save</Trans></button>
                            <Link to="/" className="btn btn-outline-primary"><Trans>btn-cancel</Trans></Link>
                        </div>
                    }
                </form>
                <hr />
                <div className="row mt-4">
                    <div className="col-12 col-lg-8 col-xl-7 col-xxl-6">
                        <DomainAuthorization />
                    </div>
                </div>
            </div>
        );
    }
}