import React from 'react';
import { NavLink } from "react-router-dom";
import { Trans } from 'react-i18next';
import { AppContext } from '../context/app.context';
import { AccessKey } from '../enum/accessKey';
import SignCountView from './signCountView';

import '../css/header.css';
import i18n from '../i18n'
import { MDBTooltip } from 'mdb-react-ui-kit'

class Header extends React.Component {
    static contextType = AppContext;
    render() {
        return (
            <>
                <header className="row">
                    <div className="col-sm-6 col-lg-6">
                        <NavLink className="profile-menu-item pb-3" activeClassName="profile-menu-item-active" exact to="/">
                            <Trans>page-documents</Trans>
                        </NavLink>
                        {this.context.access(AccessKey.settings) &&
                            <>
                                <NavLink className="profile-menu-item pb-3" activeClassName="profile-menu-item-active" to="/users">
                                    <Trans>page-users</Trans>
                                </NavLink>
                                <NavLink className="profile-menu-item pb-3" activeClassName="profile-menu-item-active" to="/log">
                                    <Trans>page-log</Trans>
                                </NavLink>
                                <NavLink className="profile-menu-item pb-3" activeClassName="profile-menu-item-active" to="/settings">
                                    <Trans>page-settings</Trans>
                                </NavLink>
                            </>
                        }
                    </div>
                    <div className="col-sm-6 col-lg-6 d-flex flex-row-reverse header-right-buttons">
                        <MDBTooltip tag="a" wrapperProps={{ className: 'p-0', style: { marginBottom: '10px' }}} placement='left' className="me-2" title={i18n.t('btn-wiki')}>
                            <a href="https://signx.cloud/training-and-setup" target="_blank" className="btn btn-primary btn-md btn-floating ms-1" role="button">
                                <i className="fas fa-graduation-cap"></i>
                            </a>
                        </MDBTooltip>
                        <MDBTooltip tag="a" wrapperProps={{ className: 'p-0', style: { marginBottom: '10px' }}} placement='left' className="me-2" title={i18n.t('btn-rates')}>
                            <a href="https://signx.cloud/training-and-setup/tariffs" target="_blank" className="btn btn-success btn-md btn-floating me-1" role="button">
                                <i className="fas fa-shopping-cart"></i>
                            </a>
                        </MDBTooltip>
                        <SignCountView />
                    </div>
                </header>
                <hr className="mt-0 mb-4" />
            </>
        );
    }

    //     <a href="https://google.com" target="_blank" className="me-2">
    //     <a className="btn btn-warning btn-md btn-floating me-1" role="button">
    //         <i className="fas fa-dollar-sign"></i>
    //     </a>
    //     <Trans>btn-buy</Trans>
    // </a>
    // <a href="https://google.com" target="_blank" className="me-2">
    //     <a className="btn btn-info btn-md btn-floating me-1" role="button">
    //         <i className="fas fa-question"></i>
    //     </a>
    //     <Trans>btn-help</Trans>
    // </a>
}

export default Header;