class SignerContact {
    id: string;
    labelCode: string;

    constructor(id: string, labelCode: string) {
        this.id = id;
        this.labelCode = labelCode;
    }
}

export const SignerContacts = {
    EMAIL: new SignerContact('Email', 'signer-contact-email'),
    PHONE: new SignerContact('Phone', 'signer-contact-phone'),
    VK: new SignerContact('Vk', 'signer-contact-vk'),
    TELEGRAM: new SignerContact('Telegram', 'signer-contact-tg'),
}