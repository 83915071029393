import React from 'react';
import Filter from '../component/filter/filter';
import FilterItem from '../component/filter/filterItem';
import FilterType from '../component/filter/filterType';
import LogTable from '../component/table/logTable';
import { Rest } from '../rest';
import { DataStorage } from "../enum/dataStorage";

export default class LogPage extends React.Component {
    constructor() {
        super();
        this.filterItems = [
            new FilterItem(FilterType.Hidden, 'id', 'field-id', { Typing: 'Y', PREFIX: 'Y' }),
            new FilterItem(FilterType.UserSelect, 'property_user', 'field-responsible', { Default: 'Y' }),
            new FilterItem(FilterType.DateRange, 'property_date', 'field-date', { NUMBER: 'Y' })
        ];

        this.table = React.createRef();
        this.filter = React.createRef();
    }

    refreshTable = () => {
        if (this.table && this.table.current) {
            this.table.current.tryLoadData();
        }
    }

    // clickEditMessage = (e, item) => {
    //     if (e) e.preventDefault();
    //     const _ = this;

    //     console.log('clickEditMessage', item);
    //     var result = prompt("new msg", item.PROPERTY_VALUES.DESCRIPTION);

    //     if (!result || result.length < 1) {
    //         console.log('cancel');
    //         return;
    //     }

    //     Rest.callMethod('entity.item.update', {
    //         ID: item.ID,
    //         ENTITY: DataStorage.log,
    //         PROPERTY_VALUES: {
    //             DESCRIPTION: result
    //         }
    //     }).then(() => {
    //         _.refreshTable();
    //     });
    // }

    render() {
        return (
            <div>
                <div className="d-xl-flex mb-3 mt-3">
                    <Filter ref={this.filter} id={'filter-log'} items={this.filterItems} refreshTable={this.refreshTable} />
                </div>
                <LogTable ref={this.table} filter={this.filter} />
            </div>
        );
    }
}